import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

function EquipePage(props: {}) {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://static.airtable.com/js/embed/embed_snippet_v1.js"
    script.async = true
    document.body.appendChild(script)
    document.body.className = 'page-equipe'
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout contentClassName="equipe container">
     <SEO title="Découvrez toute l'équipe de Shift Your Job" 
     description="Shift Your Job est un projet créé et porté par des Shifters, des bénévoles du Shift Project, think tank qui œuvre en faveur d’une économie décarbonée. Plus d'une quarantaines de volontaires ont travaillé d'arrache pied pour construire la première version du projet lancée en décembre 2020 " 
     gtmPageType="equipe" 
     gtmPageName="equipe" />
      <div className="mx-2 pt-16 pb-8 md:mx-auto md:max-w-2xl lg:max-w-3xl text-primary mb-8">
        <h1 className="text-3xl md:text-5xl leading-none text-primary font-black mb-8">
          L'équipe Shift&nbsp;Your&nbsp;Job
        </h1>
        <p>Ce projet est porté par des Shifters, des bénévoles du Shift Project, think tank qui œuvre en faveur d’une économie décarbonée.</p> 
      </div>
      
      <div className="iframe-container md:px-6 md:mx-auto">
          <iframe
            className="relative airtable-embed airtable-dynamic-height"
            src="https://airtable.com/embed/shrRrtJzghkhqdcAk?backgroundColor=yellowLight&viewControls=on"
            frameBorder="0"
            width="100%"
            height="800"
            style={{ background: "transparent" }}
          ></iframe>
        </div>
    </Layout>
  )
}

export default EquipePage
